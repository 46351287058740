import { api, apiV2, apiV2Gbm, catchHandler } from '@helpers/axios'
import config from '@config/index'
import { objToUrlParams } from '@helpers/index'

export function listMessengers(payload) {
  return api()
    .get(`${config.routes.listMessenger}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getServices(payload) {
  return api()
    .get(`${config.routes.BookingServices}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getUnreadMessages(payload) {
  return api()
    .get(`${config.routes.unreadMessages}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getAssignedMessages(payload) {
  return api()
    .get(`${config.routes.messenger}/${payload._id}/assigned`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getLeadMessages(payload) {
  return api()
    .get(`${config.routes.leadMessages}/${payload._id}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function listCompanyUsers(payload) {
  return api()
    .get(`${config.routes.companyUsers}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function messengerMessages(payload) {
  return api()
    .get(`${config.routes.messenger}/${payload._id}/messages?${objToUrlParams(payload.payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function leadsReadAt(payload) {
  return api()
    .post(`${config.routes.leads}/${payload._id}/${config.routes.readat}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function segmentTrack(payload) {
  return api()
    .post(`${config.routes.segmentTrack}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function lastAttended(payload) {
  return api()
    .post(`${config.routes.lastAttended}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function createMessage(payload) {
  return api()
    .post(`${config.routes.messenger}/${payload._id}/messages`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function createMessageThumbtack(payload) {
  let isOauthConnected = false
  if (payload.accountsettings && payload.accountsettings.links && payload.accountsettings.links.length > 0) {
    isOauthConnected = payload.accountsettings.links.find((item) => item.site === 'thumbtack')
  }
  if (isOauthConnected) {
    return apiV2()
      .post(`${config.routes.messenger}/${payload._id}/messages?${objToUrlParams(payload.query)}`, payload.data)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .post(`${config.routes.messenger}/${payload._id}/messages?${objToUrlParams(payload.query)}`, payload.data)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export function createGbmMessage(payload) {
  return apiV2Gbm()
    .post(`/${payload._id}/messages`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function createNewChatMessage(payload) {
  return api()
    .post(`${config.routes.chatNewMessage}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function createNote(payload) {
  return api()
    .post(`${config.routes.messenger}/${payload._id}${config.routes.messageNote}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function assignStaffs(payload) {
  return api()
    .post(`${config.routes.messenger}/${payload._id}${config.routes.assignStaff}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function listNote(payload) {
  return api()
    .get(`${config.routes.messenger}/${payload._id}${config.routes.messageNote}/list?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function listSegmentTrack(payload) {
  return api()
    .get(`${config.routes.segmentTrack}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function chatBulkSearch(payload) {
  return api()
    .get(`${config.routes.chatSearch}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function readAllMessages(payload) {
  return api()
    .get(`${config.routes.messenger}/${payload._id}${config.routes.allRead}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function onFavChange(payload) {
  return api()
    .post(`${config.routes.messenger}/${payload._id}/favourite`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function onStatusChange(payload) {
  return api()
    .post(`${config.routes.messenger}/${payload._id}/change-status`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getAssignedDetails(payload) {
  return api()
    .get(`${config.routes.messenger}/${payload}/assigned`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function blockUnblockContact(payload) {
  return api()
    .post(`${config.routes.blockUnblock}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateLastAttended(payload) {
  return api()
    .post(`${config.routes.lastAttended}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getMessengerNotes(payload) {
  return api()
    .get(`${config.routes.messenger}/${payload.id}/messages/note/list?${objToUrlParams(payload.params)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteMessengerNote(payload) {
  return api()
    .put(`${config.routes.messenger}/${payload.id}/messages/note`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendEmailMessage(payload) {
  return api()
    .post(`${config.routes.messenger}/sent_email`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function messengerContact(id) {
  return api()
    .get(`${config.routes.messenger}/contact/${id}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getContactList(payload) {
  return api()
    .get(`${config.routes.contactList}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function composeSms(payload) {
  return api()
    .post(config.routes.composeSms, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function setMessengerViewTime() {
  return api()
    .post(config.routes.messengerViewTime)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function messageReceivedTrack(payload) {
  return api()
    .post(config.routes.messageReceivedTrack, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendBookingLink(payload) {
  return api()
    .post(`${config.routes.bookingLink}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function allocateTwilioNumber() {
  return api()
    .post(`${config.routes.allocateTwilioNumber}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function verifyAndAssignTwilioNumber() {
  return api()
    .post(`${config.routes.verifyAndAssignTwilioNumber}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function getWhitelistHostnames() {
  return api()
    .get(`${config.routes.whitelistHostnames}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function updateMessengerTranslationPreferences(payload) {
  return api()
    .put(`${config.routes.updateMessengerTranslationPreferences}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function updateAIAssistantSettings(payload) {
  return api()
    .put(`${config.routes.updateAIAssistantSettings}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function getAIAssistantSettings(leadId) {
  return api()
    .get(`${config.routes.getAIAssistantSettings}?lead_id=${leadId}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function getSettingsData(payload) {
  return api()
    .get(`${config.routes.getsettings}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
